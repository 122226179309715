<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body flex items-center justify-center">
    <!-- Modal content -->
    <div class="modal-content p-4 bg-white rounded-lg shadow-lg max-w-[90%] sm:max-w-[70%] md:max-w-[60%] lg:max-w-[40%]">
      <div>
        <steps-completed-line :step="step" />
      </div>

      <div v-if="step == 1">
        <step-use-new-or-existing-company
          @onModalClose="$emit('onModalClose')"
          @onNext="onCompanyStepCompleted"
          @onCompayChange="onCompayChange"
          :jobDetails="jobDetails"
        />
      </div>

      <div v-if="step == 2">
        <step-job-information
          @onModalClose="onModalClose"
          @onNextStep="onJobStepCompleted"
          :jobDetails="jobDetails"
        />
      </div>

      <div v-if="step == 3">
        <step-stage-conversion
          @onModalClose="onModalClose"
          @onNextStep="onConversionStepCompleted"
          :jobDetails="jobDetails"
        />
      </div>

      <div v-if="step == 4">
        <step-time-expenditure
          @onModalClose="onModalClose"
          @onNextStep="onTimeExpenditureStepCompleted"
          :jobDetails="jobDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StepJobInformation from "../JobModal/StepOne.vue";
import StepsCompletedLine from "./stepsCompletedLine.vue";
import StepUseNewOrExistingCompany from "./StepUseNewOrExistingCompany.vue";
import StepStageConversion from "./StepStageConversion.vue";
import StepTimeExpenditure from "./StepTimeExpenditure.vue";
import {
  removeCompleteCompanyDataFromCache,
  // removeGeneralDataInCache,
} from "../../helperFunctions/DataCaching";

export default {
  props: ["job"],
  components: {
    StepsCompletedLine,
    StepUseNewOrExistingCompany,
    StepJobInformation,
    StepStageConversion,
    StepTimeExpenditure,
  },
  data() {
    return {
      jobDetails: {},
      step: 0,
      companyId: 0,
    };
  },
  methods: {
    onCompanyStepCompleted(data) {
      // set company name and id which ever it gets from useNewOrExistingCompany component
      this.jobDetails.company_id = data.newCompanyId;
      this.jobDetails.company_name = data.newCompanyName;

      this.step += 1;
    },
    onJobStepCompleted(data) {
      this.jobDetails["new_job_id"] = data.jobId;
      this.jobDetails["new_job_name"] = data.jobName;
      this.jobDetails["new_job_project_code"] = data.projectCode;
      this.step += 1;
    },
    onConversionStepCompleted() {
      this.step += 1;
    },
    onTimeExpenditureStepCompleted() {
      this.$store.dispatch("setCompany", {
        newCompanyName: this.jobDetails.company_name,
        newCompanyId: this.jobDetails.company_id,
      });

      this.$store.dispatch("setJob", {
        newJobId: this.jobDetails.new_job_id,
        newJobName: this.jobDetails.new_job_name,
      });

      removeCompleteCompanyDataFromCache(this.jobDetails.company_id);
      // removeGeneralDataInCache("allJobs"); // remove all job key from local storage, so that it gets updated data

      this.$emit("onModalClose");
      this.$router.push(
        `/dashboardJob/${this.jobDetails.company_id}/${this.jobDetails.new_job_id}`
      );
      setTimeout(() => {
        this.$router.go();
      }, 500);
    },
    onModalClose() {
      if (this.step == 1) {
        this.$emit("onModalClose");
      } else {
        alert("Sorry, you have to complete the job create process");
      }
    },
    onCompayChange(company) {
      this.jobDetails.company_id = company.id;
      this.jobDetails.company_name = company.name;
    },
  },
  mounted() {
    this.jobDetails = this.job;
    this.jobDetails.job_name = "";
    this.step = 1;
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;

  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
