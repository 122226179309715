<template>
  <div class="mt-4">
    <form class="w-full max-w-full" @submit.prevent="onSubmit">
      <div class="flex flex-wrap -mx-3 mb-0">
        <!-- looping to just avoid repeating same code  -->
        <div
          v-for="singleField in inputFields"
          :key="singleField.name"
          class="w-full md:w-1/2 px-3 mb-6 md:mb-6"
        >
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            {{ $t(`add-new-job.${singleField.text}`) }}
            {{ singleField.isMandatory && "*" }}
          </label>
          <input
            :disabled="!isAdmin"
            autocomplete="off"
            class="custom-input-style"
            id="grid-first-name"
            :type="singleField.type"
            v-model="$data[singleField.name]"
            :class="validationBorderCss(singleField)"
            @input="onValueChange($event, singleField.name)"
          />
        </div>
      </div>

      <div class="flex flex-row">
        <div v-if="!companyContactDetailsToEdit">
          <label class="label cursor-pointer">
            <input
              type="checkbox"
              v-model="isSendInvitationToClient"
              class="checkbox"
            />
            <span class="label-text ml-2">Send Invitation To Client</span>
          </label>
        </div>

        <div class="flex flex-1 flex-row w-full justify-end items-end">
          <button
            class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
            @click="onSkip()"
            type="button"
          >
            <span>{{
              this.companyContactDetailsToEdit ? "Cancel" : "Skip"
            }}</span>
          </button>
          <button
            v-if="companyContactDetailsToEdit"
            class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
            @click="onDelete()"
            type="button"
          >
            <span>Delete</span>
          </button>
          <button
            :disabled="!isAdmin"
            class="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-md primary-button"
          >
            <span>Finish</span>
          </button>
        </div>
      </div>
    </form>
    <CustomAlert ref="customAlert" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { mapActions } from "vuex";
import CustomAlert from "../../layout/CustomAlert.vue";

export default {
  components: { CustomAlert },
  props: [
    "jobId",
    "projectCode",
    "jobName",
    "companyContactDetailsToEdit",
    "contacts",
    "jobIds",
  ],
  mounted() {
    //initialize all values only when user want to edit the company info
    if (this.companyContactDetailsToEdit) {
      this.firstName = this.companyContactDetailsToEdit.first_name ?? "";
      this.lastName = this.companyContactDetailsToEdit.last_name ?? "";
      this.pronoun = this.companyContactDetailsToEdit.pronoun ?? "";
      this.position = this.companyContactDetailsToEdit.position ?? "";
      this.email = this.companyContactDetailsToEdit.email ?? "";
      this.fon = this.companyContactDetailsToEdit.fon ?? "";
      this.poc_id = this.companyContactDetailsToEdit.poc_id ?? "";
    }
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      pronoun: "",
      position: "",
      email: "",
      fon: "",
      poc_id: "",
      isValidationFail: false,
      isSendInvitationToClient: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["backendUrl", "authorizationHeader", "company", "isAdmin"]),
    // function to short html code instead of writing same field div multiple times loop them with this array
    inputFields() {
      return [
        {
          text: "company-first-name",
          name: "firstName",
          type: "text",
          isMandatory: true,
        },
        {
          text: "company-last-name",
          name: "lastName",
          type: "text",
          isMandatory: true,
        },
        { text: "company-pronoun", name: "pronoun", type: "text" },
        { text: "company-position", name: "position", type: "text" },
        {
          text: "company-email",
          name: "email",
          type: "email",
          isMandatory: true,
        },
        { text: "company-phone", name: "fon", type: "text" },
      ];
    },
    isValidated() {
      if ([this.firstName, this.lastName, this.email].includes("")) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions("job", ["setIsModal"]),
    validationBorderCss(values) {
      if (
        values.isMandatory &&
        this.$data[values.name] == "" &&
        this.isValidationFail
      )
        return "border-2 border-red-700";
      else return "border";
    },
    onSkip() {
      this.setIsModal("");
      if (this.jobId) {
        this.$router.push(`/dashboardJob/${this.company.id}/${this.jobId}`);
        this.$store.dispatch("setJob", {
          newJobId: this.jobId,
          newJobName: this.jobName,
          newKookuId: this.projectCode,
        });
      } else {
        this.$router.push(`/clients/${this.company.id}`);
      }
    },
    onDelete() {
      // if same contact found more than once it means this company contact is also in used for job
      if (
        this.contacts.filter((contact) => contact.poc_id == this.poc_id)
          .length > 1
      ) {
        this.$refs.customAlert.show(
          this.$t("add-new-job.contact-imported-in-job")
        );
      } else {
        let headers = this.authorizationHeader;

        let data = {
          job_id: this.jobId ?? "0",
          poc_id: this.poc_id,
        };

        axios
          .delete(this.backendUrl + "/contact/delete-contact", {
            headers,
            data,
          })
          .then(() => {
            this.setIsModal("");
            this.$router.go();
          })
          .catch((error) => console.log(error));
      }
    },
    onSubmit() {
      if (this.isValidated && !this.isLoading) {
        // call an api endpoint to submit complete data
        let headers = this.authorizationHeader;
        let companyData = {
          first_name: this.firstName,
          last_name: this.lastName,
          pronoun: this.pronoun,
          position: this.position,
          email: this.email,
          fon: this.fon,
          job_id: this.jobId ?? "0",
          company_id: this.jobId ? 0 : this.company.id,
          card_level: this.jobId ? 2 : 1,
          poc_id: this.poc_id,
        };
        let url = `${this.backendUrl}${
          this.poc_id != "" ? "/contact/modify-contact" : "/contact/new-contact"
        }`;
        let method = this.poc_id != "" ? "PUT" : "POST";

        this.isLoading = true;
        axios({
          method,
          url,
          headers: headers,
          data: companyData,
        })
          .then(() => {
            if (this.isSendInvitationToClient) {
              this.onInviteClient();
            } else {
              this.onRedirectToPage();
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else {
        this.isValidationFail = true;
      }
    },
    onRedirectToPage() {
      this.setIsModal("");
      this.isLoading = false;
      // if this step open from job then redirect to dashboard job page
      if (this.jobId) {
        this.$store.dispatch("setJob", {
          newJobId: this.jobId,
          newJobName: this.jobName,
          newKookuId: this.projectCode,
        });
        this.$router.push(`/dashboardJob/${this.company.id}/${this.jobId}`);
      } else {
        // if user edit the contact information
        if (this.$route.name == "ClientDetailsPage") this.$router.go();
        else this.$router.push(`/clients/${this.company.id}`);
      }
    },
    onInviteClient() {
      let body = {
        name: `${this.firstName} ${this.lastName}`,
        mail: this.email,
        roles: [{ id: 3, name: "ROLE_CLIENT" }],
        companyIds: [this.company.id],
        admin: false,
        jobIds: this.jobIds,
      };

      const headers = this.authorizationHeader;
      axios
        .post(this.backendUrl + "/auth/user/register", body, { headers })
        .then((response) => {
          // After successful user registration, associate the user with a favorite company
          body = {
            favorite_company_id: this.company.id,
            user_id: `${response.data.created_user_id}`,
          };
          axios.post(this.backendUrl + "/company/set-single", body, {
            headers,
          });
        })
        .then(() => {
          this.onRedirectToPage();
        })
        .catch((error) => console.log(error));
    },

    onValueChange(value, name) {
      this[name] = value.target.value;
    },
  },
};
</script>
