<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <form class="w-full max-w-full" @submit.prevent="onSubmit">
        <div
          class="flex flex-1 flex-col w-full justify-center items-center my-8 mx-2"
        >
          <label
            class="flex tracking-wide text-gray-700 flex-nowrap"
            for="grid-first-name"
          >
            {{ $t("common-elements.connect") }}
            <span class="uppercase tracking-wide text-gray-800 font-bold mx-1">
              {{ this.job.name }}
            </span>
            {{ $t("common-elements.withATSJob") }}
          </label>

          <div class="flex flex-1 w-full justify-center my-4">
            <company-dropdown @onCompanySelect="onCompanySelect" />
          </div>
          <div class="flex flex-1 w-full justify-center mb-4">
            <job-list-dropdown
              :company="selectedCompany"
              @onJobSelect="onJobSelect"
            />
          </div>
        </div>

        <div class="flex flex-1 flex-row w-full justify-end items-center">
          <button
            class="bg-white py-2 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
            @click="$emit('onModalClose')"
            type="button"
          >
            <span>Cancel</span>
          </button>
          <button
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
          >
            <span>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import CompanyDropdown from "../../SelectDropdowns/CompanyDropdown.vue";
import JobListDropdown from "../../SelectDropdowns/JobListDropdown.vue";
import { removeCompleteJobDataFromCache } from "../../../components/helperFunctions/DataCaching";

export default {
  props: ["job", "filteredActiveJobs"],
  components: {
    JobListDropdown,
    CompanyDropdown,
  },
  data() {
    return {
      selectedCompany: this.$store.getters.company,
      selectedJob: null,
    };
  },
  computed: {
    ...mapGetters(["backendUrl", "authorizationHeader", "companies"]),
  },
  methods: {
    onValueChange(value) {
      this.atsJobId = value;
    },
    onSubmit() {
      if (this.name == "") {
        this.isValidationFail = true;
      } else {
        // call an api endpoint to submit complete data
        let headers = this.authorizationHeader;
        let url = this.backendUrl + "/job/connect";
        let body = {
          ats_job_id: this.selectedJob.id,
          pending_job_id: this.job.id,
        };
        axios
          .post(url, body, { headers })
          .then((response) => {
            this.$emit("onWarningMsg", response.data, null);
            removeCompleteJobDataFromCache(this.selectedJob.id);
          })
          .catch((error) => {
            this.errorMsg = error.message;
            this.$emit("onWarningMsg", error.response.data, null);
          });
      }
    },
    onCompanySelect(company) {
      this.selectedCompany = company;
    },
    onJobSelect(job) {
      // job parameter contains id, name
      this.selectedJob = job;
    },
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 6% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
