<template>
  <div class="card bg-gray-200 shadow-xl my-4 mx-2 sm:mx-4 border border-gray-400 rounded-lg overflow-hidden">
    <div class="card-body p-0">
      <div class="relative card-title p-2 flex flex-col sm:flex-row">
        <!-- Container for logo and company name -->
        <div class="flex items-center mb-2 sm:mb-0 flex-grow card-header">
          <logo-container
            :initials="companyInitial(company.name)"
            :childStyle="bg"
          />
          <p
            class="text-sm ml-2 font-bold flex-grow"
            :class="$store.state.auth.isPresentationMode ? 'blur-effect' : null"
            :title="company.name" 
          >
            {{ company.name }}
          </p>
          <!-- Favorite button -->
        <div v-if="!isPendingClients" class="top-2 sm:top-4 right-2 sm:right-4">
          <favorite-button :element="company" :favorites="favorites" />
        </div>
        </div>

        
      </div>

      <div class="flex flex-col sm:flex-row justify-center items-center mt-4 sm:mt-6 px-4 sm:px-5 card-actions">
        <p class="text-sm mb-2 sm:mb-0">
          Status: <b>{{ isPendingClients ? "Pending" : $t("clients-page.active") }}</b>
        </p>
        <button
          @click="goToClientDetailsPage(company)"
          :class="detailsButtonClasses"
          class="flex items-center mt-2 sm:mt-0"
        >
          <p class="text-black">{{ $t("client-details-page.details") }}</p>
          <ArrowCircleRightIcon class="w-7 h-7 ml-2" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
//import axios from "axios";
//import { mapGetters } from "vuex";
import LogoContainer from "../../components/ui/LogoContainer.vue";
import { ArrowCircleRightIcon } from "@heroicons/vue/solid";
import FavoriteButton from "../../components/ui/FavoriteButton.vue";
import { detailsButton } from "../../components/helperFunctions/UIClasses";

export default {
  components: {
    LogoContainer,
    ArrowCircleRightIcon,
    FavoriteButton,
  },
  props: ["company", "favorites", "isPendingClients", "loading"],
  computed: {
    detailsButtonClasses() {
      return detailsButton();
    },
  },
  data() {
    return {
      bg: { background: "white", color: "grey" },
      logo: "logo_transparent.png",
    };
  },

  methods: {
    goToClientDetailsPage(company) {
      this.$store.dispatch("setCompany", {
        newCompanyName: company.name,
        newCompanyId: company.id,
      });
      this.$router.push(`/clients/${company.id}`);
    },
    companyInitial(company) {
      if (company !== undefined) {
        return (this.initials = company
          .toString()
          .replace(/[\u200B-\u200D\uFEFF]/, "")
          .substring(0, 1));
      }
    },
    mounted() {},
  },
};
</script>
<style scoped>
.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card-title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 150px;
}

.card-title p {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 50%;
  word-wrap: break-word;
  margin-left: 0.5rem;
}

.card-header{
  justify-content: space-between;
  width: -webkit-fill-available;
}

.favorite-button {
  position: absolute;
  top: 0.5rem; 
  right: 0.5rem; 
}
</style>