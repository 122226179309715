<template>
  <Menu as="div" class="inline-block text-left h-full mx-2">
    <div>
      <MenuButton
        class="inline-flex justify-center w-full rounded-lg border-2 border-black shadow-md px-4 py-2 h-full bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
      >
        {{
          isLocalSortBy
            ? isFilterActive
              ? localSortBy
              : "Sort by"
            : sortBy !== ""
            ? sortBy
            : "Sort by"
        }}
        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5 text-black" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute mt-2 w-35 border border-inherit z-50 h-30 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <div
              @click="sortAZ"
              class="cursor-pointer"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm bg-white',
              ]"
            >
              A-Z
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <div
              @click="sortZA"
              class="cursor-pointer"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm bg-white',
              ]"
            >
              Z-A
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <div
              @click="sortFav"
              class="cursor-pointer"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm bg-white',
              ]"
            >
              Favorites
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

export default {
  props: ["filteredActiveJobs", "isLocalSortBy", "isFilterActive"],
  data() {
    return {
      localSortBy: "", // this variable is used when user inside client details overview otherwise ignored and use store sortBy
    };
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  computed: {
    ...mapGetters(["sortBy"]),
  },
  methods: {
    sortAZ() {
      this.$emit("sortAz");
      this.localSortBy = "A-Z";
    },
    sortZA() {
      this.$emit("sortZa");
      this.localSortBy = "Z-A";
    },
    sortFav() {
      this.$emit("sortFav");
      this.localSortBy = "Favorites";
    },
  },
};
</script>
