<template>
  <div class="bg-gray-200 mb-4 rounded-lg pl-5 pr-5 rounded-b-lg pb-12">
    <div class="flex flex-col lg:flex-row justify-between items-center mr-8 pt-4">
      <p class="pt-6 ml-5 text-2xl lg:text-3xl font-bold">{{ currentJobName }}</p>

      <div class="flex flex-col lg:flex-row justify-between items-center pt-6">
        <modal-submit-data v-if="toggleLoading" />
        <system-info-widget
          class="text-base pb-1"
          @changeLoading="changeStatusLoading"
          :loadingToggle="loadingToggle"
        />
        <week-and-date-widget class="ml-0 lg:ml-4 mt-4 lg:mt-0" />
      </div>
    </div>

    <div class="grid grid-cols-12 gap-4 ml-3 mr-2 mt-4">
      <div class="col-span-12 lg:col-span-3">
        <project-details-timeframe-widget
          v-for="path in apiPaths"
          :key="path"
          :path="path"
          :loadingToggle="loadingToggle"
        />
      </div>
      <div class="col-span-12 lg:col-span-6 2xl:col-span-7">
        <weekly-gauge-widget :loadingToggle="loadingToggle" />
      </div>
      <div class="col-span-12 lg:col-span-3 2xl:col-span-2">
        <kpi-score-widget :loadingToggle="loadingToggle" />
      </div>
    </div>

    <div class="grid grid-cols-12 gap-4 mt-7 ml-3 mr-2">
      <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
        <Transition name="fade" mode="out-in">
          <component
            @onToggleChange="onToggleGraphTableMode"
            :loadingToggle="loadingToggle"
            :is="activeTab"
          ></component>
        </Transition>
      </div>
      <div class="col-span-12 lg:col-span-3 2xl:col-span-2">
        <time-expenditure-widget :loadingToggle="loadingToggle" class="mb-4 lg:mb-0" />
        <time-tracking-gauge-widget :loadingToggle="loadingToggle" />
      </div>
    </div>

    <div class="bg-white p-3 mt-7 mb-4 rounded-3xl shadow-2xl mr-2 ml-3">
      <donut-card-container
        :loadingToggle="loadingToggle"
        v-if="roleName && !isRoleClient"
      />
      <conversion-rate-widget :loadingToggle="loadingToggle" />
    </div>

    <div class="grid grid-cols-12 gap-4 mt-7 ml-3 mr-2">
      <div class="col-span-12 lg:col-span-4">
        <candidates-in-process-widget :loadingToggle="loadingToggle" />
      </div>
      <div class="col-span-12 lg:col-span-8">
        <disqualification-reasons-widget :loadingToggle="loadingToggle" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import ProjectDetailsTimeframeWidget from "./ProjectDetailandTimeFrameWidgets/ProjectDetailsTimeframeWidget.vue";
import ActualTargetComparisonWidget from "./ActualTargetComparisonWidget/ActualTargetComparisonWidget.vue";
import KpiScoreWidget from "./KpiScoreWidget/KpiScoreWidget.vue";
import TimeExpenditureWidget from "./TimeExpenditureWidget/TimeExpenditureWidget.vue";
import DonutCardContainer from "./DonutWidget/DonutCardContainer.vue";
import ConversionRateWidget from "./ConversionRateWidget/ConversionRateWidget.vue";
import SystemInfoWidget from "../../components/widgets/SystemInfoWidget.vue";
import WeekAndDateWidget from "./WeekAndDateWidget.vue";
import ProjectStatusWidget from "./ProjectStatusWidget/ProjectStatusWidget.vue";
import CandidatesInProcessWidget from "./CandidatesInProcess/CandidatesInProcessWidget.vue";
import ModalSubmitData from "./ModalSubmitData.vue";
import WeeklyGaugeWidget from "../../components/widgets/WeeklyGaugeWidget.vue";
import TimeTrackingGaugeWidget from "../../components/widgets/TimeTrackingGaugeWidget.vue";
import DisqualificationReasonsWidget from "./DisqualificationReasons/DisqualificationReasonsWidget.vue";
import {
  getGeneralDataInCache,
  jobDataInCache,
} from "../../components/helperFunctions/DataCaching";

export default {
  components: {
    ProjectDetailsTimeframeWidget,
    KpiScoreWidget,
    TimeExpenditureWidget,
    ConversionRateWidget,
    SystemInfoWidget,
    WeekAndDateWidget,
    DonutCardContainer,
    ProjectStatusWidget,
    CandidatesInProcessWidget,
    ModalSubmitData,
    WeeklyGaugeWidget,
    TimeTrackingGaugeWidget,
    ActualTargetComparisonWidget,
    DisqualificationReasonsWidget,
  },
  mounted() {
    // logic to store all project preferences values in store
    // toggle project preference and close it instantly so that values of specific job stored in store
    // check if data is in cache by provide any random key of any widget, if yes then don't toggle
    this.$store.dispatch("toggleSidePanelWithHidden", true);
    setTimeout(() => {
      this.$store.dispatch("toggleSidePanelWithHidden", false);
    }, 50);

    if (!this.isRoleClient)
      setTimeout(() => {
        this.checkIfUserExist();
      }, 5000);
  },
  data() {
    return {
      apiPaths: ["/widget/project-details"],
      loadingToggle: false,
      isGraphMode: true,
      activeTab: "ProjectStatusWidget",
      isSubjectMatterUserExist: false,
    };
  },
  computed: {
    currentJobName() {
      return this.$store.getters.currentJobName;
    },

    ...mapGetters([
      "toggleLoading",
      "authorizationHeader",
      "backendUrl",
      "currentJobId",
      "isSidePanelHidden",
      "isOpen",
      "isRoleClient",
      "roleName",
    ]),
  },
  methods: {
    checkIfUserExist() {
      let subjectMatterUser = jobDataInCache(
        this.currentJobId,
        "GeneralProjectInformation"
      )[7];
      let userList = getGeneralDataInCache("userList");

      // Check if the subject matter user exists in the user list
      if (!userList.some((user) => user.name === subjectMatterUser.value)) {
        alert("Attention! No Recruiting Specialist is assigned for this job.");
      }
    },
    onToggleGraphTableMode() {
      this.isGraphMode = !this.isGraphMode;
      if (this.isGraphMode) this.activeTab = "ProjectStatusWidget";
      else this.activeTab = "ActualTargetComparisonWidget";
    },
    fetchDataCompaniesGoBackButton() {
      let companies = this.$store.getters.storeState.project.companies;

      let companySelectWithParam = companies.filter((company) => {
        return company.id == document.URL.split("/").reverse()[1];
      });
      this.$store.dispatch("setCompany", {
        newCompanyName: companySelectWithParam[0].name,
        newCompanyId: companySelectWithParam[0].id,
      });

      this.fetchDataJobGoBackButton(companySelectWithParam[0].id);
    },

    fetchDataJobGoBackButton(id) {
      const headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + "/menu/jobs-by-company/" + id, {
          headers,
        })
        .then((response) => {
          let jobs = response.data;

          let selected = jobs.filter(
            (job) => job.id == document.URL.split("/").slice(-1)[0]
          );
          this.$store.dispatch("setJob", {
            newJobId: selected[0].id,
            newJobName: selected[0].name,
            newKookuId: selected[0].kookuId,
          });
        })
        .catch((error) => console.log(error));
    },
    changeStatusLoading(data) {
      this.loadingToggle = data;
    },
  },
  updated() {
    window.onpopstate = () => {
      this.fetchDataCompaniesGoBackButton();
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .mr-8 {
    margin-right: 0;
  }
  .pt-6 {
    padding-top: 1rem;
  }
  .ml-5 {
    margin-left: 1rem;
  }
  .text-2xl {
    font-size: 1.25rem;
  }
  .ml-4 {
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
  .lg\\:col-span-9 {
    grid-column: span 12 / span 12;
  }
  .lg\\:col-span-3 {
    grid-column: span 12 / span 12;
  }
  .lg\\:col-span-6 {
    grid-column: span 12 / span 12;
  }
}
</style>
