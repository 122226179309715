<template>
  <div class="relative z-10 flex-shrink-0 flex h-18 shadow-md glass-effect bg-white appHeader" id="appHeader">
    <!-- Sidebar toggle button (uncomment if needed) -->
    <!-- <button
      class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 sidebarHide shadow-md md:hidden"
      @click="openSidebar"
    >
      <span class="sr-only">Open sidebar</span>
      <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
    </button> -->

    <div class="flex-1 px-4 flex flex-wrap items-center justify-between" style="flex-flow: row;">
      <!-- Logo -->
      <img
        class="h-9 w-auto cursor-pointer"
        src="@/assets/logo_transparent.png"
        alt="Kooku Logo"
        @click="goBackToClientsOverview()"
      />

      <!-- Conditionally shown elements -->
      <div class="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">

        <company-select-menu
          v-if="(currentRouteName === 'DashboardJob' || currentRouteName === 'Home') && !isPresentationMode && !isRoleClient"
        />
        <single-client-selector
          v-if="(currentRouteName === 'ClientsOverview' || currentRouteName === 'ClientDetailsPage') && !isPresentationMode && !isRoleClient"
        />
        <jobs-client-selector
          v-if="currentRouteName === 'ClientDetailsPage' && !isPresentationMode && !isRoleClient"
        />
        <job-select-menu
          v-if="(currentRouteName === 'DashboardJob' || currentRouteName === 'Home') && !isPresentationMode && !isRoleClient"
        />
      </div>

      <!-- Request New Project Button -->
      <div
        class="absolute right-4 top-1/2 transform -translate-y-1/2 rounded-xl p-2 px-4 primary-button cursor-pointer hidden md:block"
        @click="onOpenModal('requestNewProjectModal')"
        v-if="isRoleClient"
      >
        <p class="font-semibold text-white">
          {{ $t("common-elements.RequestNewProject") }}
        </p>
      </div>

      <!-- User Dropdown Menu -->
      <div class="flex flex-row">
        <user-dropdown-menu />
      </div>

      <!-- Modals -->
      <feedback-modal @onModalClose="onModalClose" v-if="isModal == 'feedbackModal'" />
      <request-new-job-modal @onModalClose="onModalClose" v-if="isModal == 'requestNewProjectModal'" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import JobSelectMenu from "./JobSelectMenu.vue";
import CompanySelectMenu from "./CompanySelectMenu.vue";
import UserDropdownMenu from "./UserDropdownMenu.vue";
import SingleClientSelector from "../../../views/ClientDetailsPage/SingleClientSelector.vue";
import JobsClientSelector from "../../../views/ClientDetailsPage/JobsClientSelector.vue";
import FeedbackModal from "../../Modals/FeedbackModal/FeedbackModal.vue";
import RequestNewJobModal from "../../Modals/RequestNewJob/RequestNewJobModal.vue";
import {
  getGeneralDataInCache,
  removeGeneralDataInCache,
  storeGeneralDataInCache,
} from "../../helperFunctions/DataCaching";

export default {
  data() {
    return {
      isModal: "",
    };
  },
  components: {
    RequestNewJobModal,
    JobSelectMenu,
    CompanySelectMenu,
    UserDropdownMenu,
    SingleClientSelector,
    JobsClientSelector,
    FeedbackModal,
  },
  computed: {
    isAdminValue() {
      return this.$store.getters.isAdmin;
    },
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters([
      "isPresentationMode",
      "isRoleClient",
      "isSuperAdmin",
      "isRoleUser",
      "isAdmin",
    ]),
  },
  methods: {
    ...mapActions([
      "toggleProjectPreferences",
      "setUserInfo",
      "setActualUserDetails",
    ]),
    ...mapActions("sidebar", ["openSidebar"]),
    onBackToAdmin() {
      if (getGeneralDataInCache("actualSuperAdmin")) {
        let userInfo = getGeneralDataInCache("actualSuperAdmin");
        let tempUser = {
          isAdmin: userInfo.admin,
          mail: userInfo.mail,
          name: userInfo.name,
          roleName: userInfo.roleName,
        };
        removeGeneralDataInCache("actualSuperAdmin");
        storeGeneralDataInCache("user", tempUser);

        this.setUserInfo({ ...tempUser });
        this.setActualUserDetails({ ...tempUser });
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
    redirectToAdminPage() {
      this.$router.replace("/admin");
    },
    goBackToClientsOverview() {
      this.$router.push("/clients");
    },
    // generic function of model open on button click (pass modal name in parameter)
    onOpenModal(modalName) {
      this.isModal = modalName;
    },
    // function to close modal if want to close without timeout then set porameter to true
    onModalClose(isFromCloseButton) {
      setTimeout(
        () => {
          this.isModal = "";
        },
        isFromCloseButton ? 0 : 2000
      );
    },
  },
};
</script>

<style>
@media print {
  #appHeader {
    display: none;
  }
}

@media only screen and (max-width: 924px) {
  .sidebarHide {
    display: block;
  }
  .logoMobile {
    display: block;
  }

  .appHeader {
    border-bottom-width: 0;
    border-color: transparent;
  }
}

@media only screen and (min-width: 925px) {
  .sidebarHide {
    display: none;
  }
  .logoMobile {
    display: none;
  }

}
</style>
