<template>
  <skeleton-candidates-in-process-widget v-if="loading" />
  <div v-else class="flex flex-col w-full mr-2 mt-2">
    <div class="-my-2 overflow-x-auto rounded-lg">
      <div
        class="pt-2 align-middle inline-block w-full px-6 pb-6 bg-white rounded-3xl"
      >
        <div class="rounded-lg bg-white relative">
          <label class="block text-2xl text-black px-4 pt-4">
            {{ $t(`candidateInProcess.${name}`) }}
          </label>
          <div class="absolute top-1 right-1 text-6xl text-gray-500 font-bold">
            {{ this.numberOfCandidates }}
          </div>
          <div>
            <div class="bg-white w-full pl-5 pr-2 py-1 mt-4 text-base text-gray-500">
              <ul v-for="(data, index) in captionData" :key="data">
                <div class="flex flex-row">
                  <li>{{ $t(`candidateInProcess.${rowHeaders[index]}`) }}:</li>
                  <li class="ml-2" v-for="value in data" :key="value">
                    {{ value }}
                  </li>
                </div>
              </ul>
            </div>
          </div>
          <table class="min-w-full divide-gray-200">
            <thead class="bg-white">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  v-for="header in columnHeaders"
                  v-bind:key="header"
                >
                  {{ $t(`candidateInProcess.${header}`) }}
                </th>
              </tr>
            </thead>
            <div
              class="my-2 ml-4 text-gray-400"
              v-if="candidatesData.length == 0"
            >
              {{ $t("dashboard.no-candidates") }}
            </div>
            <tbody v-else>
              <tr
                v-for="(data, index) in candidatesData"
                :key="data"
                :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'"
                class="rounded-lg"
              >
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  :class="
                    $store.state.auth.isPresentationMode && i == 0
                      ? 'blur-effect'
                      : null
                  "
                  v-for="(value, i) in data"
                  :key="value"
                >
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../../../components/helperFunctions/DataCaching";
import SkeletonCandidatesInProcessWidget from "./SkeletonCandidatesInProcessWidget.vue";

export default {
  props: ["loadingToggle"],
  components: { SkeletonCandidatesInProcessWidget },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
    toggleLoadingSaveButton() {
      return this.$store.getters.toggleLoading;
    },
  },

  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
    toggleLoadingSaveButton() {
      this.fetchDataFromBackend(this.currentJobId);
    },
  },
  data() {
    return {
      name: "",
      columnHeaders: [],
      rowHeaders: [],
      tableData: [],
      captionData: [],
      loading: true,
      candidatesData: [],
      numberOfCandidates: null,
    };
  },

  methods: {
    fetchDataFromBackend(jobId) {
      if (jobId !== null) {
        this.loading = true;
        const headers = this.authorizationHeader;
        axios
          .get(
            this.backendUrl +
              "/widget/candidates-in-process" +
              "?jobId=" +
              jobId,
            {
              headers,
            }
          )
          .then((response) => {
            storeJobDataInCache(jobId, response.data, "CandidatesInProcess");
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.name = data.name;
      this.columnHeaders = data.column_headers;
      this.tableData = data.table_data;
      this.captionData = data.table_data.slice(0, 2);
      this.candidatesData = data.table_data.slice(2);
      this.rowHeaders = data.row_headers;
      this.loading = false;
      this.calculateNumberOfCandidates(this.captionData);
    },
    calculateNumberOfCandidates(tableData) {
      this.numberOfCandidates =
        this.candidatesData.length +
        tableData.reduce((a, b) => Number(a) + Number(b), 0);
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "CandidatesInProcess")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "CandidatesInProcess")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
<style scoped>
.newWidth {
  width: 38%;
}
tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: solid 1px lightgray;
}
tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
