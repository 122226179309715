<template>
  <button
    class="btn btn-circle btn-ghost hover:bg-[#bdb011]/20"
    @click="selectOrRemoveFavo"
  >
    <StarIcon class="w-6 h-6" v-if="!isFavorite" style="stroke: black" />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      viewBox="0 0 20 20"
      fill="currentColor"
      style="fill: #fbea1f; stroke: black; stroke-width: 1.5;"
      v-else
    >
      <path
        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
      />
    </svg>
  </button>
</template>
<script>
import { mapGetters } from "vuex";
import { StarIcon } from "@heroicons/vue/outline";

export default {
  props: ["element", "favorites"],
  emits: ["selectOrRemoveFavorite"],
  components: {
    StarIcon,
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  watch: {
    favorites() {
      this.displaySelectedStar();
    },
  },

  computed: {
    ...mapGetters(["backendUrl", "authorizationHeader"]),
  },
  methods: {
    selectOrRemoveFavo() {
      this.$parent.$emit("selectOrRemoveFavorite");
    },
    displaySelectedStar() {
      if (this.favorites) {
        if (this.favorites.includes(Number(this.element.id))) {
          this.isFavorite = true;
        } else {
          this.isFavorite = false;
        }
      }
    },
  },
  mounted() {
    this.displaySelectedStar();
  },
};
</script>
