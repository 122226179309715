<template>
  <Listbox as="div" v-model="selected" class="mr-2">
    <div
      @click="showListCompany"
      class="flex flex-row border-2 w-56 mt-1 md:max-w-xs rounded-lg border-black p-2 ml-2 sm:text-sm align-middle smallScreenSelectWidth"
    >
      <div class="mr-2 w-5/6">
        <p class="truncate" id="selectedItem" v-if="selected">
          {{ selected.name }}
        </p>
        <p v-else id="selectedItem">{{ $t("clients-page.all-clients") }}</p>
      </div>
      <SelectorIcon class="h-5 text-black w-1/6" aria-hidden="true" />
    </div>
    <transition name="dropdown">
      <ul
        v-if="showList"
        class="absolute z-10 ml-2 mt-1 w-56 md:max-w-xs smallScreenSelectWidth sm:w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      >
        <div class="flex justify-center mb-3">
          <input
            class="appearance-none block text-gray-700 focus:outline-none focus:bg-white border p-1 w-72 rounded-md"
            placeholder="Search Company"
            @input="onCompanyFilter($event)"
            id="companySearch"
          />
        </div>

        <li
          class="p-2 flex flex-row sm:text-xs"
          :class="{ active: active_el == null }"
          @click="goToClientsOverview"
        >
          {{ $t("clients-page.all-clients") }}
          <CheckIcon
            v-if="active_el == null"
            class="h-5 ml-1 w-1/5"
            aria-hidden="true"
          />
        </li>
        <li
          @click="chosedCompany(company)"
          v-for="company in filterCompanies"
          :key="company"
          class="sm:text-xs p-2 flex flex-row"
          :class="{ active: active_el == company.id }"
        >
          {{ company.name }}
          <CheckIcon
            v-if="active_el == company.id"
            class="h-5 ml-1 w-1/5"
            aria-hidden="true"
          />
        </li>
      </ul>
    </transition>
  </Listbox>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import { Listbox } from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Listbox,
    CheckIcon,
    SelectorIcon,
  },
  watch: {
    selected(newSelected) {
      if (this.selected) {
        this.$store.dispatch("setCompany", {
          newCompanyName: newSelected.name,
          newCompanyId: newSelected.id,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl"]),
  },
  data() {
    return {
      companies: [],
      filterCompanies: [],
      selected: null,
      showList: false,
      active_el: null,
    };
  },
  methods: {
    onCompanyFilter(value) {
      this.filterCompanies = this.companies.filter((company) =>
        company.name.toLowerCase().includes(value.target.value.toLowerCase())
      );
    },
    showListCompany() {
      this.filterCompanies = [...this.companies];
      this.showList = !this.showList;
    },
    chosedCompany(newSelected) {
      this.$store.dispatch("setCompany", {
        newCompanyName: newSelected.name,
        newCompanyId: newSelected.id,
      });
      this.$router.push(`/clients/${newSelected.id}`);
      this.selected = newSelected;
      this.activate(newSelected.id);
      this.showList = false;
    },
    activate(el) {
      this.active_el = el;
    },
    selectComp(id) {
      let companySelectWithParam = this.companies.filter((company) => {
        return company.id == id;
      });
      this.selected = companySelectWithParam[0];
    },
    fetchDataFromBackend() {
      // check condition is companies list is already there then do not call the api again
      if (this.$store.getters.storeState?.project?.companies?.length <= 0) {
        this.$store.dispatch("resetCompanyVariables"); // to reset all variables to empty array before API hit

        const headers = this.authorizationHeader;
        axios
          .get(this.backendUrl + "/company/all-companies", { headers })
          .then((response) => {
            this.sortCompanies(response.data);
          })
          .catch((error) => console.log(error));
      } else {
        this.sortCompanies(this.$store.getters.storeState.project.companies);
      }
    },
    sortCompanies(companyList) {
      this.companies = companyList;
      this.$store.dispatch("setAllCompany", this.companies);
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));

      this.companies.sort((a, b) => a.name.localeCompare(b.name));
      this.filterCompanies = [...this.companies];
      if (this.$route.params.id !== null) {
        this.activate(this.$route.params.id);
        this.selectComp(this.$route.params.id);
      }
    },
    fetchDataFromBackendGoBackButton() {
      let companies = this.$store.getters.storeState.project.companies;
      this.filterCompanies = this.$store.getters.storeState.project.companies;

      this.$store.dispatch("setAllCompany", this.companies);

      let companySelectWithParam = companies.filter((company) => {
        return company.id == this.$route.params.id;
      });

      this.selected = companySelectWithParam[0];
      this.activate(this.selected.id);
      this.selectComp(this.selected.id);
    },
    goToClientsOverview() {
      this.$router.push("/clients");
    },
  },
  mounted() {
    this.fetchDataFromBackend();

    //  logic to close job list from header, check click event and if it is not occur from dropdown then close it else dont do anything
    document.body.addEventListener(
      "click",
      (event) => {
        if (!["selectedItem", "companySearch"].includes(event.target.id)) {
          this.filterCompanies = [...this.companies];
          this.showList = this.showList && false;
        }
      },
      true
    );
  },
  updated() {
    window.onpopstate = () => {
      if (!isNaN(document.URL.split("/").slice(-1)[0])) {
        this.fetchDataFromBackendGoBackButton();
      }
    };
  },
};
</script>
<style scoped>
@media only screen and (max-width: 349px) {
  .smallScreenSelectWidth {
    width: 12rem;
  }
}
ul > li:hover {
  cursor: pointer;
}
.active {
  color: white;
  font-weight: bold;
  background-color: rgb(79 70 229);
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.5s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
