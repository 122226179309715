<template>
  <div class="login-page">
    <div class="flex flex-1 flex-col justify-center lg:flex-row">
      <div
        class="background-section hidden lg:block"
        style="flex: 1.5">
      </div>

      <div class="flex flex-col items-center justify-center login-form-container ml-10 mr-10" style="flex: 0.6">
        <div class="mb-16">
          <img
            class="mx-auto h-12 w-auto"
            src="@/assets/logo_transparent.png"
            alt="Kooku Logo"
          />
        </div>

        <p class="text-3xl font-thin text-center">
          {{
            isForgetPassword
              ? $t(`login-page.password-reset`)
              : $t(`login-page.welcome`)
          }}
        </p>

        <form class="mt-16 w-[60%]" action="#" v-on:submit="onSubmit">
          <input type="hidden" name="remember" value="true" />

          <div v-if="isForgetPassword">
            <p class="italic text-sm mb-3">
              {{ $t("login-page.forget-password-message") }}
            </p>
          </div>

          <div>
            <p class="text-red-600 mb-2 text-center" v-if="invalidEmailPwText">
              {{ $t("login-page.invalid-credentials-message") }}
            </p>
            <label for="email" class="sr-only">
              {{ $t("login-page.email-address") }}</label
            >
            <input
              id="email"
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="custom-input-style"
              :placeholder="$t('login-page.email-address')"
            />
          </div>

          <div v-if="!isForgetPassword">
            <label for="password" class="sr-only">{{
              $t("login-page.password")
            }}</label>
            <input
              id="password"
              v-model="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              class="custom-input-style mt-5"
              :placeholder="$t('login-page.password')"
            />
          </div>

          <div class="text-sm" v-if="!isForgetPassword">
            <a
              @click="onForgetPassword"
              href="#"
              class="font-medium text-blue-600 hover:text-blue-500 underline"
            >
              Forgot Password?
            </a>
          </div>

          <div class="flex flex-1 justify-center mt-10">
            <button
              type="submit"
              class="bg-gray-300 hover:bg-gray-400 font-medium py-2 px-4 rounded-lg w-40 justify-center inline-flex items-center shadow-md primary-button text-white"
            >
              {{
                isForgetPassword
                  ? $t("login-page.submit")
                  : $t("login-page.sign-in")
              }}
            </button>
          </div>

          <div class="flex flex-1 justify-center mt-3">
            <p
              class="cursor-pointer text-xs text-blue-600 hover:text-blue-500 underline"
              @click="onModalOpen('requestForLoginModal')"
            >
              Request a Client Login
            </p>
          </div>
        </form>
      </div>
    </div>
    <div v-if="isModal === 'requestForLoginModal'">
      <request-for-login-form @onModalClose="onModalClose" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import RequestForLoginForm from "../components/Hubspot/RequestForLoginForm.vue";
export default {
  data() {
    return {
      email: "",
      password: "",
      mode: "login",
      isLoading: false,
      error: null,
      invalidEmailPwText: false,
      isForgetPassword: false,
      isModal: "",
    };
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl"]),
  },
  components: {
    RequestForLoginForm,
  },
  mounted() {
    // check if useralready logged in then redirect it to clients page
    if (localStorage.getItem("user")) this.$router.push(`/clients`);
  },
  methods: {
    onModalOpen(modalName) {
      this.isModal = modalName;
    },
    onModalClose() {
      this.isModal = "";
    },
    onForgetPassword() {
      this.isForgetPassword = true;
    },
    onSubmit(e) {
      e.preventDefault();

      if (
        this.email === "" ||
        !this.email.includes("@") ||
        this.password.length < 2
      ) {
        return;
      } else if (this.isForgetPassword) this.passwordReset();
      else this.login();
    },
    passwordReset() {
      axios.get(
        this.backendUrl + `/auth/user/resetPassword?email=${this.email}`
      );
      alert("Please check your email for password reset link");
      this.isForgetPassword = false;
    },
    async login() {
      this.isLoading = true;

      const loginCredentials = {
        email: this.email,
        password: this.password,
      };

      try {
        if (this.mode === "login") {
          await this.$store.dispatch("login", loginCredentials);
        } else {
          await this.$store.dispatch("signup", loginCredentials);
        }
        localStorage.setItem("cookieTime", JSON.stringify(new Date()));

        localStorage.setItem("jobDetailsCacheFromBackend", JSON.stringify({}));
        localStorage.setItem(
          "companyDetailsCacheFromBackend",
          JSON.stringify({})
        );

        this.invalidEmailPwText = false;

        this.$router.push("/clients");
        // this.$router.replace("/greetings");
      } catch (error) {
        this.invalidEmailPwText = true;
        this.error = error.message || "Failed to authenticate, try later";
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #e5e7eb;
}

.background-section {
  background-image: url('@/assets/kookuLogo.png');
  background-size:cover;
  background-position: center;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.login-form-container {
  flex: 0.8;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.custom-input-style {
  width: 100%;
  padding: 12px 15px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
}

.custom-input-style:focus {
  border-color: #3ea3dc;
  box-shadow: 0 0 0 3px rgba(58, 44, 129, 0.2);
  outline: none;
}
</style>
