<template>
  <skeleton-details-card-widgets :lines="9" v-if="loading" />
  <div class="flex flex-col mt-12 lg:mt-16">
    <div class="-my-2 overflow-x-auto rounded-lg mb-4">
      <div
        class="pt-2 align-middle inline-block min-w-full px-3 lg:px-6 pb-6 bg-white rounded-3xl"
      >
        <div class="rounded-lg bg-white">
          <!-- Responsive label -->
          <label class="block text-lg lg:text-2xl text-gray-700 p-2 lg:p-4 mb-2 lg:mb-3">
            {{ $t(`detailsProjectAnalysis.Projektanalyse`) }}
          </label>

          <!-- Responsive table -->
          <table class="min-w-full divide-gray-200">
            <thead class="bg-white">
              <tr>
                <th
                  scope="col"
                  class="px-2 lg:px-6 py-3 text-left text-xs lg:text-sm font-medium text-gray-500 uppercase tracking-wider"
                  v-for="header in columnHeaders"
                  v-bind:key="header"
                >
                  {{ $t(`detailsProjectAnalysis.${header}`) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in tableData"
                :key="data"
                :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'"
                class="rounded-lg"
              >
                <td
                  class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap text-xs lg:text-sm font-medium text-gray-900"
                  :class="
                    keyword.length > 0 &&
                    keyword !== undefined &&
                    rowHeaders[index]
                      .toLowerCase()
                      .includes(keyword.toLowerCase()) &&
                    'text-red-400'
                  "
                >
                  {{ $t(`detailsProjectAnalysis.${rowHeaders[index]}`) }}
                </td>

                <td
                  class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap text-xs lg:text-sm text-gray-500 border-l-2"
                  v-for="value in data"
                  :key="value"
                >
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../../../components/helperFunctions/DataCaching";

import SkeletonDetailsCardWidgets from "./SkeletonDetailsCardWidgets.vue";
export default {
  components: {
    SkeletonDetailsCardWidgets,
  },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
    keyword() {
      return this.$store.getters.keywords;
    },
  },

  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
  },
  data() {
    return {
      name: "",
      columnHeaders: [],
      rowHeaders: [],
      tableData: [],
      hasRowHeaders: false,
      loading: true,
    };
  },
  methods: {
    fetchDataFromBackend(jobId) {
      if (jobId !== null) {
        this.loading = true;
        const headers = this.authorizationHeader;
        axios
          .get(
            this.backendUrl + "/widget/project-analysis" + "?jobId=" + jobId,
            {
              headers,
            }
          )
          .then((response) => {
            storeJobDataInCache(jobId, response.data, "ProjectAnalysis");
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.name = data.name;
      this.columnHeaders = data.column_headers;
      this.tableData = data.table_data;
      this.rowHeaders = data.row_headers;
      this.loading = false;
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "ProjectAnalysis")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "ProjectAnalysis")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
<style scoped>
tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media (max-width: 768px) {
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .text-xs {
    font-size: 0.75rem;
  }
  .text-lg {
    font-size: 1.125rem;
  }
}
</style>
