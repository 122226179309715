<template>
  <skeleton-donut-widget v-if="loading" />
  <div v-else class="flex flex-row flex-wrap justify-center lg:justify-start">
    <div
      class="shadow rounded-lg bg-gray-50 w-64 flex flex-col items-center p-2 mt-4 mb-3 ml-3 mx-3"
      v-for="(data, index) in tableData"
      :key="data"
    >
      <donut-card :data="data" :rowheaders="rowHeaders[index]" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../../../components/helperFunctions/DataCaching";
import DonutCard from "./DonutCard.vue";
import SkeletonDonutWidget from "./SkeletonDonutWidget.vue";

export default {
  props: ["loadingToggle"],
  components: { DonutCard, SkeletonDonutWidget },
  data() {
    return {
      tableData: [],
      rowHeaders: [],
      loading: true,
    };
  },
  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
    toggleLoadingSaveButton() {
      this.fetchDataFromBackend(this.currentJobId);
    },
  },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
    toggleLoadingSaveButton() {
      return this.$store.getters.toggleLoading;
    },
  },
  methods: {
    fetchDataFromBackend(jobId) {
      this.loading = true;
      if (jobId !== null) {
        const headers = this.authorizationHeader;
        axios
          .get(this.backendUrl + "/widget/donuts" + "?jobId=" + jobId, {
            headers,
          })
          .then((response) => {
            storeJobDataInCache(jobId, response.data, "DonutCardContainer");
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.rowHeaders = data.row_headers;

      this.tableData = data.table_data;
      this.loading = false;

      if (Object.prototype.hasOwnProperty.call(data, "row_headers")) {
        this.hasRowHeaders = true;
        this.rowHeaders = data.row_headers;
      }
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "DonutCardContainer")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "DonutCardContainer")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
