<template>
  <div class="relative">
    <div @click="toggleShowMenu" class="mr-5 cursor-pointer font-bold">
      <logo-container aria-hidden="true" :initials="initials" :childStyle="bgStyle" style="border-width: 2px; border-color: black;"/>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-if="showMenu" class="fixed inset-0 flex z-50">
        <div class="fixed inset-0 bg-black bg-opacity-50" @click="toggleShowMenu"></div>
        <div class="relative bg-white w-72 max-w-full h-full overflow-y-auto shadow-lg md:mt-16 md:right-5 md:top-0 md:absolute md:h-auto md:rounded-md">
          <div class="mb-2 bg-white">
            <div class="flex flex-row items-center p-4" style="border-bottom-width: medium;">
              <div class="flex-col m-2">
                <b><p class="capitalize text-lg">{{ user.name }}</p></b>
                <p class="text-gray-500 text-sm">{{ user.mail }}</p>
                <p v-if="user.isAdmin" class="text-xs text-gray-400">Admin</p>
              </div>
            </div>
          </div>
          <div class="py-1">
            <div v-if="isAdminValue">
              <div @click="redirectToOverviewPage" class="text-gray-700 group cursor-pointer flex items-center pl-5 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">
                <ChartBarIcon class="mr-3 h-5 w-5" style="color:#3ea3dc;" aria-hidden="true" />
                Overview
              </div>
            </div>

            <div v-if="isAdminValue">
              <div @click="redirectToAdminPage" class="text-gray-700 cursor-pointer group flex items-center pl-5 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">
                <UserIcon class="mr-3 h-5 w-5" style="color:#3ea3dc;" aria-hidden="true" />
                {{ $t("right-menu-mobile.admin") }}
              </div>
            </div>

            <div class="py-1" v-if="isAdminValue">
              <div class="cursor-not-allowed text-gray-700 group flex items-center pl-5 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">
                <LinkIcon class="mr-3 h-5 w-5" style="color:#3ea3dc;" aria-hidden="true" />
                {{ $t("right-menu-mobile.ats-settings") }}
              </div>
            </div>
          </div>
          <div class="py-1">
            <div class="py-1">
            <div @click="logout" class="primary-button rounded-lg w-10/12 my-0 mx-auto py-2 flex justify-center text-sm text-gray-200 group hover:bg-[#3ea3dc] cursor-pointer">
              <LogoutIcon class="mr-3 h-5 w-5 text-gray-100" aria-hidden="true" />
              {{ $t("right-menu-mobile.logout") }}
            </div>
          </div>

            <div class="grid grid-cols-2 p-4">
              <div>
                <locale-switcher />
              </div>
              <div v-if="isAdminValue">
                <PresentationModeSwitcher />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import LocaleSwitcher from "./LocaleSwitcher.vue";
import LogoContainer from "../../ui/LogoContainer.vue";

import {
  LinkIcon,
  UserIcon,
  LogoutIcon,
  ChartBarIcon,
} from "@heroicons/vue/outline";
import PresentationModeSwitcher from "./PresentationModeSwitcher.vue";

export default {
  components: {
    LocaleSwitcher,
    LogoContainer,
    LinkIcon,
    UserIcon,
    LogoutIcon,
    PresentationModeSwitcher,
    ChartBarIcon,
  },
  data() {
    return {
      showMenu: false,
      bgStyle: {
        background: "rgb(229 231 235)",
        // border: "solid 2px black",
        width: "3rem",
      },
      userInfoStyle: {
        background: "rgb(229 231 235)",
        border: "solid 2px black",
        width: "3.5rem",
        height: "3.5rem",
      },
    };
  },
  computed: {
    isAdminValue() {
      return this.$store.getters.isAdmin;
    },
    initials() {
      return this.$store.getters.userInitials;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    ...mapActions(["toggleProjectPreferences", "isAdmin"]),

    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
    redirectToOverviewPage() {
      this.$router.push("/overview");
    },
    redirectToAdminPage() {
      this.$router.push("/admin");
    },
    atsService() {
      this.$router.push("/atssettings");
    },
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style scoped>
@media print {
  #appHeader {
    display: none;
  }
}
</style>
