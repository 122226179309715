<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <form
        class="w-full max-w-full"
        @submit.prevent="$emit('onModalCloseOrRedirect')"
      >
        <div
          class="flex flex-1 flex-col w-full justify-center items-center my-8 mx-2"
        >
          <label class="flex tracking-wide text-gray-700" for="grid-first-name">
            {{ message }}
          </label>
          <br />
          <label class="flex tracking-wide text-gray-300">
            {{ $t("confirm-msg.redirect") }}
          </label>
        </div>
        <div class="flex flex-1 flex-row w-full justify-center items-center">
          <button
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
            @click="autoClose"
          >
            <span>{{ $t("confirm-msg.confirm") }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message", "redirectUrl"],
  mounted() {
    setTimeout(() => {
      this.autoClose();
    }, 3000);
  },
  methods: {
    autoClose() {
      this.$router.push(this.redirectUrl);
    },
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 8% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
