<template>
  <div class="mt-4" v-if="false">
    <form class="w-full max-w-full" @submit.prevent="onSubmit">
      <div class="flex flex-wrap -mx-3 mb-0">
        <!-- looping to just avoid repeating same code  -->
        <div
          v-for="singleField in inputFields"
          :key="singleField.name"
          class="w-full md:w-1/2 px-3 mb-6 md:mb-6"
        >
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            {{ $t(`add-new-job.${singleField.text}`) }}
          </label>
          <input
            autocomplete="off"
            class="custom-input-style"
            id="grid-first-name"
            type="number"
            @input="onValueChange($event, singleField.name)"
            v-model="$data[singleField.name]"
          />
        </div>
      </div>

      <div class="flex flex-row justify-end items-end">
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
          @click="$emit('onModalClose')"
          type="button"
        >
          <span>Cancel</span>
        </button>
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center shadow-md"
        >
          <span>Next</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: ["jobId", "jobName", "projectCode"],

  data() {
    return {
      applicationManagement: "6",
      candidateIdent: "9",
      candidateApproach: "3",
      feedbackTotal: "6",
      interviewInterest: "60",
      appointmentCustomer: "30",
      personalConversation: "60",
      projectResearch: "25",
    };
  },
  mounted() {
    // not so dirty fix
    // just hit the end point when this component is rendered and hide step from front end
    this.onSubmit();
  },
  computed: {
    ...mapGetters(["selectedCompanyId", "authorizationHeader", "backendUrl"]),

    // function to short html code instead of writing same field div multiple times loop them with this array
    inputFields() {
      return [
        { text: "job-application-management", name: "applicationManagement" },
        { text: "job-candidate-ident", name: "candidateIdent" },
        { text: "job-candidate-approach", name: "candidateApproach" },
        { text: "job-feedback-total", name: "feedbackTotal" },
        { text: "job-interview-interest", name: "interviewInterest" },
        { text: "job-appointment-customer", name: "appointmentCustomer" },
        { text: "job-personal-conversation", name: "personalConversation" },
        { text: "job-project-research", name: "projectResearch" },
      ];
    },
  },
  methods: {
    onSubmit() {
      // call an api endpoint to submit complete data
      let data = {
        job_id: this.jobId,
        percentage_project_management: this.projectResearch,
        time_expenditure_conducting_phone_interview: this.interviewInterest,
        time_expenditure_contacting_candidates: this.candidateApproach,
        time_expenditure_handling_replies: this.feedbackTotal,
        time_expenditure_having_personal_meeting: this.personalConversation,
        time_expenditure_identifying_candidates: this.candidateIdent,
        time_expenditure_managing_applications: this.applicationManagement,
        time_expenditure_managing_phone_interview_with_client:
          this.appointmentCustomer,
      };
      let headers = this.authorizationHeader;

      axios
        .put(this.backendUrl + `/job/estimated-time-expenditure`, data, {
          headers,
        })
        .then(() => {
          this.$emit("onNextStep");
        });
    },
    onValueChange(value, name) {
      this[name] = value.target.value;
    },
  },
};
</script>
