<template>
  <div class="mt-4">
    <form class="w-full max-w-full" @submit.prevent="onSubmit">
      <div class="flex flex-wrap -mx-3 mb-0">
        <!-- looping to just avoid repeating same code  -->
        <div
          v-for="singleField in inputFields"
          :key="singleField.name"
          class="w-full md:w-1/2 px-3 mb-6 md:mb-6"
        >
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            {{ $t(`add-new-job.${singleField.text}`) }}
            {{ singleField.isMandatory && "*" }}
          </label>
          <input
            autocomplete="off"
            class="custom-input-style"
            :class="validationBorderCss(singleField)"
            id="grid-first-name"
            :type="singleField.type"
            @input="onValueChange($event, singleField.name)"
            v-model="$data[singleField.name]"
          />
        </div>

        <div key="AssignedRecruiter" class="w-full md:w-1/2 px-3 mb-6 md:mb-6">
          <assign-recruiter
            @change="setAssignedRecruiter"
            :data="assignRecruiterData"
          />
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3">
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-last-name"
          >
            Status
          </label>
          <select
            class="custom-select-style"
            id="grid-state"
            v-model="status"
            :disabled="jobDetails"
          >
            <option
              v-for="singleOption in selectOptions"
              :key="singleOption"
              :value="singleOption"
            >
              {{ singleOption }}
            </option>
          </select>
        </div>
      </div>

      <div class="flex flex-row justify-end items-end">
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
          @click="$emit('onModalClose')"
          type="button"
        >
          <span>Cancel</span>
        </button>
        <button
          class="bg-gray-300 hover:bg-gray-400 font-bold py-2 px-4 rounded inline-flex items-center shadow-md primary-button text-white"
        >
          <span>Next</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import AssignRecruiter from "../../projectPreferences/AssignRecruiter.vue";

export default {
  props: ["companyId", "jobDetails"],
  components: {
    AssignRecruiter,
  },
  data() {
    return {
      name: "",
      projectStart: "",
      noOfOpenings: "1",
      estDurationInWeeks: "",
      generalContactPerson: "Mathias",
      status: "In Planung",
      professionalContactPerson: "Mathias",
      isValidationFail: false,
    };
  },
  mounted() {
    if (this.jobDetails) {
      let headers = this.authorizationHeader;

      axios
        .get(
          this.backendUrl +
            `/widget/project-details?jobId=${JSON.parse(
              this.jobDetails.job_id
            )}`,
          {
            headers,
          }
        )
        .then((response) => {
          let data = response.data.table_data;
          this.name = this.jobDetails.job_name;
          this.projectStart = this.dateFormat();
          this.estDurationInWeeks = this.calculateWeeks(data[4][0], data[5][0]);
          this.noOfOpenings = data[2][0];
        })
        .catch((error) => console.log(error));
    } else {
      this.projectStart = this.dateFormat();
    }
  },
  computed: {
    ...mapGetters(["selectedCompanyId", "authorizationHeader", "backendUrl"]),

    // function to short html code instead of writing same field div multiple times loop them with this array
    inputFields() {
      return [
        { text: "company-name", name: "name", type: "text", isMandatory: true },
        { text: "job-project-start", name: "projectStart", type: "date" },
        { text: "job-num-opening", name: "noOfOpenings", type: "number" },
        {
          text: "job-est-duration-in-weeks",
          name: "estDurationInWeeks",
          type: "number",
          isMandatory: true,
        },
        // {
        //   text: "job-professional-contact-person",
        //   name: "professionalContactPerson",
        //   type: "text",
        // },
      ];
    },
    selectOptions() {
      return ["In Planung", "Läuft", "Beendet"];
    },

    isValidated() {
      if ([this.name, this.estDurationInWeeks].includes("")) {
        return false;
      } else {
        return true;
      }
    },

    assignRecruiterData() {
      return {
        id: "professional_contact_person",
        label: "Fachlicher Ansprechpartner",
      };
    },
  },
  methods: {
    calculateWeeks(startDateStr, endDateStr) {
      // Function to calculate the number of weeks between two dates
      const startDate = new Date(...startDateStr.split(".").reverse());
      const endDate = new Date(...endDateStr.split(".").reverse());

      const timeDifference = endDate - startDate;

      // Calculate the number of weeks by dividing the time difference by the number of milliseconds in a week
      const weeks = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));

      return weeks;
    },
    setAssignedRecruiter({ value }) {
      this.professionalContactPerson = value.name;
    },
    dateFormat(dateStr) {
      // Formats a date string into the format "YYYY-MM-DD". If no date string is provided, the current date is used.
      const dateObj = dateStr
        ? new Date(...dateStr.split(".").reverse())
        : new Date();

      const year = dateObj.getFullYear();
      const month = `${(dateObj.getMonth() + 1).toString().padStart(2, "0")}`;
      const date = `${dateObj.getDate().toString().padStart(2, "0")}`;

      return `${year}-${month}-${date}`;
    },
    validationBorderCss(values) {
      if (
        values.isMandatory &&
        this.$data[values.name] == "" &&
        this.isValidationFail
      )
        return "border-2 border-red-700";
      else return "border";
    },
    onSubmit() {
      if (this.isValidated) {
        this.name = `${this.name} - (Created in Dashr)`;
        // call an api endpoint to submit complete data
        let data = {
          name: this.name,
          status: this.status,
          project_start: this.projectStart,
          num_openings: this.noOfOpenings,
          estimated_duration_in_weeks: this.estDurationInWeeks,
          general_contact_person: this.generalContactPerson,
          professional_contact_person: this.professionalContactPerson,
          company_id: this.jobDetails
            ? this.jobDetails.company_id
            : this.selectedCompanyId,
          project_code: "XX_000",
        };
        let headers = this.authorizationHeader;
        axios
          .post(this.backendUrl + `/job/pending-general-info`, data, {
            headers,
          })
          .then((response) => {
            let jobId = response.data;
            let parameters = {
              jobId,
              jobName: this.name,
              projectCode: "XX_000",
            };
            this.$emit("onNextStep", parameters);
          });
      } else {
        this.isValidationFail = true;
        alert("Please fill all mandatory fields");
      }
    },
    onValueChange(value, name) {
      this[name] = value.target.value;
    },
  },
};
</script>
