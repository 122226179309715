<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <form class="w-full max-w-lg mx-auto p-6 rounded-lg" @submit.prevent="onSubmit">
        <!-- Form Header with Separate Lines -->
        <div class="text-center mb-6 space-y-1">
          <p class="text-lg font-semibold text-gray-700 tracking-wide">
            {{ $t("common-elements.transfer") }}
          </p>
          <p class="uppercase font-bold text-gray-800">
            {{ job.name }}
          </p>
          <p class="text-lg font-semibold text-gray-700 tracking-wide">
            {{ $t("common-elements.toClient") }}
          </p>
        </div>

        <!-- Company Dropdown -->
        <div class="my-4" style="text-align: -webkit-center;">
          <company-dropdown @onCompanySelect="onCompanySelect" />
        </div>

        <!-- Action Buttons -->
        <div class="flex justify-end space-x-3 mt-6">
          <button
            type="button"
            @click="$emit('onModalClose')"
            class="py-2 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="py-2 px-4 border border-transparent rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import CompanyDropdown from "../../SelectDropdowns/CompanyDropdown.vue";
import { removeCompleteJobDataFromCache } from "../../helperFunctions/DataCaching";

export default {
  props: ["job", "filteredActiveJobs"],
  components: {
    CompanyDropdown,
  },
  data() {
    return {
      selectedCompany: this.$store.getters.company,
      selectedJob: null,
    };
  },
  computed: {
    ...mapGetters(["backendUrl", "authorizationHeader", "companies"]),
  },
  methods: {
    onValueChange(value) {
      this.atsJobId = value;
    },
    onSubmit() {
      if (this.name == "") {
        this.isValidationFail = true;
      } else {
        // call an api endpoint to submit complete data
        let headers = this.authorizationHeader;
        let url = this.backendUrl + "/job/transfer";
        let body = {
          job_id: this.job.id,
          company_id: this.selectedCompany.id,
        };
        axios
          .post(url, body, { headers })
          .then((response) => {
            this.$emit("onWarningMsg", response.data, null);
            removeCompleteJobDataFromCache(this.selectedCompany.id);
          })
          .catch((error) => {
            this.errorMsg = error.message;
            this.$emit("onWarningMsg", error.response.data, null);
          });
      }
    },
    onCompanySelect(company) {
      this.selectedCompany = company;
    },
    onJobSelect(job) {
      // job parameter contains id, name
      this.selectedJob = job;
    },
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 6% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
