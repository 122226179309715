<template>
  <button
    class="h-9 mr-6 rounded-lg bg-gray-400 p-2 flex flex-row items-center justify-center text-base shadow-md primary-button"
    @click="fetchDataFromBackend"
    :disabled="isRoleClient"
  >
    <RefreshIcon
      v-if="!isRoleClient"
      class="h-5 w-5 ml-2 cursor-pointer"
      aria-hidden="true"
      :class="{ icon: showRotate }"
    />
    {{ isRoleClient ? message : $t("detailsCard.fetch-job-details") }}
  </button>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { RefreshIcon } from "@heroicons/vue/outline";
import { removeCompleteJobDataFromCache } from "../helperFunctions/DataCaching";

export default {
  components: {
    RefreshIcon,
  },
  data() {
    return {
      showRotate: false,
    };
  },
  props: ["message"],
  computed: {
    ...mapGetters([
      "authorizationHeader",
      "backendUrl",
      "currentJobId",
      "isRoleClient",
    ]),
  },

  methods: {
    fetchDataFromBackend() {
      const jobId = this.$route.params.jobId;
      const headers = this.authorizationHeader;

      removeCompleteJobDataFromCache(jobId);

      this.showRotate = true;
      this.$parent.$emit("changeLoading", true);

      axios
        .get(this.backendUrl + "/load-job-increment" + "?jobId=" + jobId, {
          headers,
        })
        .then(() => {
          this.showRotate = false;
          this.$parent.$emit("changeLoading", false);
          this.$router.go();
        });
    },
  },
};
</script>
<style scoped>
.icon {
  animation: rotateIcon 2s linear infinite;
}
@keyframes rotateIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
