<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <div class="border-b-2 border-slate-400 pb-3">
        <p class="text-2xl font-semibold">
          {{ $t("projectStatusChanges.time-frame") }}
        </p>
      </div>

      <div class="grid grid-cols-4 gap-4 mt-5 break-words">
        <div v-for="data in inputData" v-bind:key="data.id">
          <div>
            <label
              :for="data.id"
              class="block text-sm font-medium text-gray-700"
            >
              {{ $t(`timeTracking.${data.label}`) }}</label
            >
            <input
              min="0"
              :type="data.type"
              :id="data.id"
              :value="data.value"
              v-on:input="onValueChange(data, $event.target.value)"
              class="mb-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>

      <div class="mt-5">
        <vc-donut
          :sections="sections"
          has-legend
          :auto-adjust-text-size="true"
          :thickness="50"
        >
          <p class="font-semibold">
            {{
              (
                afterSettingHours -
                this.TimeTrackingGaugeDetails.time_Info
                  .estimatedTotalTimeToSpend
              ).toFixed(2)
            }}
            hrs
          </p>
        </vc-donut>
      </div>

      <div class="flex flex-row justify-end items-end mt-10">
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
          @click="$emit('onModalClose')"
          type="button"
        >
          <span>Cancel</span>
        </button>
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
          @click="onResetValues()"
          type="button"
        >
          <span class="capitalize">Reset</span>
        </button>
        <button
          @click="onSubmit()"
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center shadow-md primary-button"
        >
          <span>Submit Changes</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import calculateHours from "../../../../components/helperFunctions/CalculateProjectHours.js";
import * as constants from "../../../../constant/Constants";
import { removeCompleteJobDataFromCache } from "../../../../components/helperFunctions/DataCaching";

export default {
  props: ["TimeTrackingGaugeDetails"],
  components: {},
  data() {
    return {
      inputData: [],
      sections: [],
      daysToKpiGoal: 0,
      afterSettingHours: 0,
      rowHeaders: [],
      tableData: [],
      loading: true,
    };
  },
  methods: {
    ...mapActions(["setFieldValues"]),

    onSubmit() {
      let body = { job_id: this.$route.params.jobId };
      this.inputData.forEach((ele) => (body[ele.id] = ele.value));
      const headers = this.authorizationHeader;
      axios
        .put(
          `${this.backendUrl}/job/estimated-time-expenditure?jobId=${this.$route.params.jobId}`,
          body,
          { headers }
        )
        .then(() => {
          removeCompleteJobDataFromCache(this.$route.params.jobId);

          this.$router.go();
        })
        .catch((error) => console.log(error));
    },
    onValueChange(data, value) {
      //change value of inputData
      let index = this.inputData.findIndex(
        (singleObject) => singleObject.id == data.id
      );
      this.inputData[index] = { ...data, value: parseInt(value) };
      this.onCalculateHours();
    },
    calculateDonutValues() {
      const hoursFromBackend =
        this.TimeTrackingGaugeDetails.time_Info.estimatedTotalTimeToSpend;
      let daysAfter = parseInt(this.afterSettingHours) / 24;
      let daysBefore = parseInt(hoursFromBackend) / 24;
      let percentageAfter = 0;
      let percentageBefore = 0;
      percentageAfter = parseInt(((daysAfter / daysBefore) * 100).toFixed(2));
      percentageBefore = parseInt(((daysBefore / daysAfter) * 100).toFixed(2));

      if (percentageBefore > 100) {
        percentageBefore =
          percentageBefore - 50 > 99 ? 99 : percentageBefore - 50;
        percentageAfter = 100 - percentageBefore;
      } else {
        percentageAfter = percentageAfter - 50 > 99 ? 99 : percentageAfter - 50;
        percentageBefore = 100 - percentageAfter;
      }
      let afterBarColor =
        this.afterSettingHours > hoursFromBackend
          ? "#f87171"
          : this.afterSettingHours < hoursFromBackend
          ? "#4ade80"
          : "#4b5563";

      this.sections = [
        {
          label: `After: ${this.afterSettingHours.toFixed(2)} hr`,
          value: percentageAfter,
          color: afterBarColor,
        },
        {
          label: `Before: ${JSON.parse(hoursFromBackend).toFixed(2)} hr`,
          value: percentageBefore,
          color: "#9ca3af",
        },
      ];
    },
    onResetValues() {
      this.inputData.map((singleValue, index) => {
        this.inputData[index] = {
          ...this.inputData[index],
          value: constants[singleValue.id.toUpperCase()],
        };
      });
      this.onCalculateHours();
    },
    onCalculateHours() {
      let hours = calculateHours(
        this.inputData,
        this.EstimateStageConversion[0].value,
        this.projectStatusBar
      );
      this.afterSettingHours = isNaN(hours) ? 0 : hours;
      this.calculateDonutValues();
    },
    calDaysToKpiGoal() {
      const projectEndDate = new Date(
        this.timeFrame["Planmäßiges Projektende"].split(".").reverse().join(".")
      );
      const currentDate = new Date();
      // if difference is in negative number that means the deadline hass passed
      if (projectEndDate - currentDate < 0) {
        this.daysToKpiGoal = 0;
        return;
      }
      const diffTime = Math.abs(currentDate - projectEndDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      this.daysToKpiGoal = diffDays;
    },
  },
  mounted() {
    this.afterSettingHours = parseFloat(
      this.TimeTrackingGaugeDetails.time_Info.estimatedTotalTimeToSpend
    );
    this.calculateDonutValues();
    this.calDaysToKpiGoal();
    this.inputData = JSON.parse(
      JSON.stringify([...this.Estimatedtimeexpenditure])
    );
  },
  computed: {
    ...mapGetters([
      "backendUrl",
      "authorizationHeader",
      "Estimatedtimeexpenditure",
      "projectStatusBar",
      "EstimateStageConversion",
      "currentJobId",
    ]),
    ...mapGetters("job", ["timeFrame"]),
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 6% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
