<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Listbox as="div" v-model="selected">
    <div
      @click="showListJobs"
      class="flex flex-row border-2 w-64 mt-1 md:w-80 md:max-w-xs rounded-lg border-gray-300 p-2 ml-2 sm:text-sm align-middle smallScreenSelectWidth widthSelectorBetweenMdLg"
    >
      <div class="mr-2 truncate w-5/6" id="selectedItem">
        {{ selected.name }}
      </div>

      <SelectorIcon class="h-5 text-gray-400 w-1/6" aria-hidden="true" />
    </div>
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ul
        v-if="showList"
        class="absolute z-6 ml-2 mt-1 w-64 md:w-80 smallScreenSelectWidth widthSelectorBetweenMdLg bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      >
        <div class="flex justify-center mb-3">
          <input
            class="appearance-none block text-gray-700 focus:outline-none focus:bg-white border p-1 w-72 rounded-md"
            placeholder="Search Job"
            @input="onJobFilter($event)"
            id="jobSearch"
          />
        </div>
        <li
          @click="chosedJob(job)"
          v-for="job in filterJobs"
          :key="job"
          class="sm:text-xs p-2 flex flex-row"
          :class="{
            active: active_el == job.id,
          }"
        >
          {{ job.name }}
          <CheckIcon
            v-if="active_el == job.id"
            class="h-5 ml-1 w-1/5"
            aria-hidden="true"
          />
        </li>
      </ul>
    </transition>
  </Listbox>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import { Listbox } from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Listbox,
    SelectorIcon,
    CheckIcon,
  },
  watch: {
    selected(newSelected) {
      this.$store.dispatch("setJob", {
        newJobId: newSelected.id,
        newJobName: newSelected.name,
        newKookuId: newSelected.kookuId,
      });
    },
    selectedCompanyId(id) {
      this.fetchDataFromBackend(id);
    },
    currentJob() {
      this.fetchDataFromBackend(this.selectedCompanyId);
    },
  },
  computed: {
    ...mapGetters(["selectedCompanyId", "authorizationHeader", "backendUrl"]),
    currentJob() {
      return this.$store.getters.currentJobId;
    },
    company() {
      return this.$store.getters.company;
    },
  },
  data() {
    return {
      jobs: [],
      filterJobs: [],
      selected: "",
      showList: false,
      active_el: null,
    };
  },
  methods: {
    onJobFilter(value) {
      this.filterJobs = this.jobs.filter((job) =>
        job.name.toLowerCase().includes(value.target.value.toLowerCase())
      );
    },
    showListJobs() {
      this.filterJobs = [...this.jobs];
      this.showList = !this.showList;
    },
    chosedJob(newSelected) {
      this.$store.dispatch("setJob", {
        newJobId: newSelected.id,
        newJobName: newSelected.name,
        newKookuId: newSelected.kookuId,
      });
      this.$router.push(`/dashboardjob/${this.company.id}/${newSelected.id}`);
      this.selected = newSelected;
      this.activate(newSelected.id);
      this.showList = false;
    },
    activate(el) {
      this.active_el = el;
    },
    fetchDataFromBackend(companyId) {
      const headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + "/menu/jobs-by-company/" + companyId, {
          headers,
        })
        .then((response) => {
          this.jobs = response.data;
          this.jobs.sort((a, b) => a.name.localeCompare(b.name));
          this.filterJobs = [...this.jobs];
          if (this.currentJob == null) {
            this.selected = this.jobs[0];
          } else {
            this.selected = this.jobs.filter(
              (job) => job.id == this.currentJob
            );
            this.chosedJob(this.selected[0]);
          }
          this.$router.push(`/dashboardjob/${companyId}/${this.selected.id}`);
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    this.fetchDataFromBackend(this.company.id);

    //  logic to close job list from header, check click event and if it is not occur from dropdown then close it else dont do anything
    document.body.addEventListener(
      "click",
      (event) => {
        if (!["selectedItem", "jobSearch"].includes(event.target.id)) {
          this.filterJobs = [...this.jobs];
          this.showList = this.showList && false;
        }
      },
      true
    );
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1024px) and (max-width: 1035px) {
  .widthSelectorBetweenMdLg {
    max-width: 350px;
  }
}

@media only screen and (max-width: 349px) {
  .smallScreenSelectWidth {
    width: 12rem;
    max-width: 18rem;
  }
}
ul > li:hover {
  cursor: pointer;
}
.active {
  color: white;
  font-weight: bold;
  background-color: rgb(79 70 229);
}
</style>
