<template>
  <div
    class="
      flex
      items-center
      justify-center
      border-2
      rounded-lg
      h-full
      bg-white
    "
  >
    <div class="flex">
      <SearchIcon class="w-4 h-4 my-auto mx-1" />

      <input
        type="text"
        class="px-4 py-1 border-none rounded-lg !bg-transparent"
        v-model="searched"
        :placeholder="$t('detailsCard.placeholder-searchbar')"
      />
    </div>
  </div>
</template>

<script>
import { SearchIcon } from "@heroicons/vue/solid";

export default {
  components: {
    SearchIcon,
  },

  data() {
    return {
      searched: "",
    };
  },

  watch: {
    searched(newVal) {
      this.$store.dispatch("setKeywords", newVal);
    },
  },
};
</script>
