<template>
  <div class="card-container">
  <div
    class="card flex w-80 h-80 p-4 bg-white hover:bg-white shadow-xl btn-ghost border border-gray-400"
  >
    <div class="relative flex-col justify-between h-full flex-1">
      <div class="flex flex-row justify-between flex-1">
        <div class="flex flex-row justify-between flex-1">
          <p class="text-sm w-full font-semibold">{{ job.name }}</p>
        </div>
        <div>
          <favorite-button
            v-if="!isInPlanning"
            class="w-6 h-6 mr-1"
            :element="job"
            :favorites="favorites"
          />
        </div>

        <div
          v-if="isInPlanning && isAdmin"
          @mouseover="infoBoxForEdit = true"
          @mouseleave="infoBoxForEdit = false"
        >
          <DotsHorizontalIcon
            @click="onShowEditMenu"
            class="w-5 h-5 text-gray-700 mx:5"
          />
          <!-- <div class="info-box" v-if="infoBoxForEdit == true">
            <label class="flex text-sm text-gray-500"> more actions </label>
          </div> -->
        </div>
      </div>

      <div
        class="absolute left-44 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
        v-if="showEditMenu"
      >
        <div class="py-1" role="none">
          <a
            href="#"
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
            @click="$emit('onJobDeleteConfirm', this.job), closeEditMeu()"
          >
            {{ $t("common-elements.delete") }}
          </a>
          <a
            href="#"
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
            @click="$emit('onTransferJob', this.job), closeEditMeu()"
          >
            {{ $t("detailsCard.transfer-job") }}
          </a>
          <a
            href="#"
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
            @click="$emit('onConnectAtsJob', this.job), closeEditMeu()"
          >
            {{ $t("detailsCard.connect-ats-job") }}
          </a>
        </div>
      </div>

      <div class="flex flex-1 flex-col bg-white rounded-lg w-full mt-5">
        <div class="flex flex-row justify-between flex-1">
          <div class="flex flex-col justify-between flex-1">
            <p class="text-xs mb-3 font-semibold">
              {{ $t("add-new-job.company-point-of-contact") }}:
            </p>
            <button
              v-for="singleJobContact in jobContacts"
              :key="singleJobContact"
              :class="
                $store.state.auth.isPresentationMode ? 'blur-effect' : null
              "
              class="text-xs bg-white hover:bg-gray-100 text-gray-800 py-1 px-4 border border-gray-400 rounded mb-2 shadow-md"
              @click="onContactModal(singleJobContact)"
              :disabled="!isAdmin"
            >
              {{ singleJobContact.first_name }}
              {{ singleJobContact.last_name }}
              ({{ singleJobContact.email }})
            </button>
          </div>
          <div class="flex flex-row">
            <button
              v-if="isAdmin"
              @click="onContactModal()"
              class="text-black flex flex-row h-8 text-sm font-semibold"
            >
              <PlusCircleIcon class="w-5 h-5 hover:text-gray-500 text-black" />
            </button>
            <button
              v-if="isAdmin"
              @click="$emit('onOpenContactsImport', this.job.id, jobContacts)"
              class="text-black flex flex-row h-8 text-sm font-semibold ml-2"
            >
              <DownloadIcon class="w-6 h-6 hover:text-gray-500 text-black" />
            </button>
          </div>
        </div>
        <div></div>
      </div>

      <div class="flex flex-1 flex-row absolute bottom-0 items-end">
        <div>
          <vue-gauge
            class="bg-transparent"
            v-if="kpiScore !== '' && !isInPlanning"
            :refid="`job-id-${job.id}`"
            :options="kpiScoreGaugeOptions"
          />
        </div>
        <div>
          <button @click="goToDashboard" :class="detailsButtonClasses">
            {{ $t("detailsCard.to-project") }}
            <ChevronRightIcon class="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
  <modify-contact-modal
    v-if="isModalShow"
    @onModalClose="onModalClose"
    :contactDetail="{
      ...jobContactToOpenOrEdit,
      job_id: this.job.id,
      companyId: selectedCompanyId,
    }"
  />
</template>
<script>
import axios from "axios";
import VueGauge from "vue-gauge";

import FavoriteButton from "../../components/ui/FavoriteButton.vue";
import {
  PlusCircleIcon,
  DotsHorizontalIcon,
  DownloadIcon,
  ChevronRightIcon,
} from "@heroicons/vue/solid";
import ModifyContactModal from "../../components/Modals/ModifyContactModal/ModifyContactModal.vue";
import { mapGetters } from "vuex";
import { companyDataInCache } from "../../components/helperFunctions/DataCaching";
import { detailsButton } from "../../components/helperFunctions/UIClasses";

export default {
  props: [
    "job",
    "selectedCompanyId",
    "company",
    "favorites",
    "isInPlanning",
    "contacts",
  ],
  emits: [
    "onContactModalFinish",
    "selectFav",
    "removeFav",
    "onJobDeleteConfirm",
    "onConnectAtsJob",
    "onTransferJob",
    "onOpenContactsImport",
    "selectOrRemoveFavorite",
  ],
  components: {
    PlusCircleIcon,
    DotsHorizontalIcon,
    FavoriteButton,
    ModifyContactModal,
    DownloadIcon,
    ChevronRightIcon,
    VueGauge,
  },
  computed: {
    ...mapGetters(["isAdmin", "authorizationHeader", "backendUrl"]),
    isAnyActiveJobs() {
      // get the job list of this company and check if that list contain any job with status Läuft, if no then return false else true
      let jobList = companyDataInCache(this.selectedCompanyId, "jobsList");
      let index = jobList.findIndex((value) => value.status == "Läuft");
      return index == -1 ? false : true;
    },
    detailsButtonClasses() {
      return detailsButton();
    },
    kpiScoreGaugeOptions() {
      let kpiPercentage = JSON.parse(this.kpiScore.split("%")[0]);
      let needleValue = kpiPercentage;

      if (kpiPercentage > 20 && kpiPercentage <= 50) needleValue = 20;
      else if (kpiPercentage > 50 && kpiPercentage <= 90) needleValue = 37;
      else if (kpiPercentage > 90 && kpiPercentage <= 120) needleValue = 57;
      else if (kpiPercentage > 120 && kpiPercentage <= 150) needleValue = 77;
      else if (kpiPercentage > 150) needleValue = 97;

      return {
        arcPadding: 0,
        needleValue: needleValue,
        arcDelimiters: [20, 40, 60, 80, 99],
        arcColors: [
          "#f87171",
          "#45d67a",
          "#37c069",
          "#24a252",
          "#12853c",
          "#12853c",
        ],
        arcLabels: [],
        needleColor: "rgb(75 85 99)",
        centralLabel: this.kpiScore,
        rangeLabel: ["KPI Score", ""],
        arcOverEffect: false,
        chartWidth: 150,
      };
    },
  },
  data() {
    return {
      jobContacts: [],
      isModalShow: false,
      showEditMenu: false,
      infoBoxForEdit: false,
      jobContactToOpenOrEdit: {},
      kpiScore: "",
    };
  },
  watch: {
    contacts() {
      this.filterContactForJob();
    },
  },
  mounted() {
    this.fetchKpiScoreData();
    this.filterContactForJob();
  },
  methods: {
    filterContactForJob() {
      let filterJobContact = this.contacts.filter(
        (value) => value.job_id == this.job.id
      );
      this.jobContacts = filterJobContact;
    },
    onContactModal(contactDetails) {
      this.jobContactToOpenOrEdit = contactDetails;
      this.isModalShow = true;
    },
    onModalClose() {
      this.isModalShow = false;
      this.$emit("onContactModalFinish");
    },
    onShowEditMenu() {
      this.showEditMenu = !this.showEditMenu;
      this.infoBoxForEdit = false;
    },
    closeEditMeu() {
      this.showEditMenu = !this.showEditMenu;
    },
    goToDashboard() {
      this.$store.dispatch("setJob", {
        newJobId: this.job.id,
        newJobName: this.job.name,
        newKookuId: this.job.kookuId,
      });

      this.$router.push(
        `/dashboardJob/${this.selectedCompanyId}/${this.job.id}`
      );
    },
    fetchKpiScoreData() {
      const headers = this.authorizationHeader;
      axios
        .get(
          this.backendUrl +
            "/widget/project-kpi-score" +
            "?jobId=" +
            this.job.id,
          {
            headers,
          }
        )
        .then((response) => {
          this.kpiScore = response.data.table_data[0][0];
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped>
.info-box {
  background-color: #fefefe;
  margin: 3px;
  padding: 3px;
  /* border: 1px solid #888; */
  width: fit-content;
  block-size: fit-content;
  position: absolute;
}
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 1rem; /* Space between cards */
  width: 100%; /* Ensure container takes full width */
  justify-items: center; /* Center items horizontally */
}

.card {
  max-width: 100%; /* Ensure cards don't exceed container width */
}

/* Adjustments for medium screens (tablets, etc.) */
@media (max-width: 1024px) {
  .card-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Smaller min-width */
  }
  .card {
    max-width: 100%; /* Ensure full-width on medium screens */
  }
}

/* Adjustments for small screens (phones) */
@media (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Stack cards more easily */
  }
  .card {
    max-width: 100%; /* Full width for small screens */
  }
}

/* Extra small screens */
@media (max-width: 640px) {
  .card-container {
    grid-template-columns: 1fr; /* Single column layout */
  }
  .card {
    width: 100%; /* Full width for cards */
  }
}
</style>
