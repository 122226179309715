<template>
    <div
    class="bg-gray-200 p-3 pt-4 mx-2 my-5 shadow-xl rounded-lg btn-ghost border border-gray-400"
  >
    <div class="flex flex-col lg:flex-row items-center justify-between">
      <!-- Left Section -->
      <div class="flex items-center mb-2 ">
        <favorite-button
          v-if="!isPendingClients"
          :element="company"
          :favorites="favorites"
        />
        
      </div>
      <p
          class="ml-2 text-xl lg:mb-0 lg:w-2/4"
          :class="$store.state.auth.isPresentationMode ? 'blur-effect' : null"
        >
          {{ company.name }}
        </p>
      <!-- Status Section -->
      <p class="text-xs mb-2 lg:mb-0 lg:w-1/4 text-center lg:text-left">
        Status:
        <b>{{ isPendingClients ? "Closed" : $t("client-details-page.completed") }}</b>
      </p>

      <!-- Button Section -->
      <div class="w-full lg:w-auto flex justify-center lg:justify-end">
        <button
          :class="detailsButtonClasses"
          @click="goToClientDetailsPage(company)"
          class="flex items-center"
        >
          <p class="text-black">{{ $t("client-details-page.details") }}</p>
          <ArrowCircleRightIcon class="w-7 h-7 ml-2" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FavoriteButton from "../../components/ui/FavoriteButton.vue";
import { detailsButton } from "../../components/helperFunctions/UIClasses";
import { ArrowCircleRightIcon } from "@heroicons/vue/solid";
export default {
  props: ["company", "favorites", "isPendingClients"],
  components: {
    FavoriteButton,
    ArrowCircleRightIcon,
  },
  computed: {
    detailsButtonClasses() {
      return detailsButton();
    },
  },
  methods: {
    goToClientDetailsPage(company) {
      this.$store.dispatch("setCompany", {
        newCompanyName: company.name,
        newCompanyId: company.id,
      });
      this.$router.push(`/clients/${company.id}`);
    },
  },
};
</script>
